<template>
  <div>
    <!--    <b-button class="mb-2" variant="primary" @click="$bvToast.show('welcome-message')">-->
    <!--      Show toast-->
    <!--    </b-button>-->
    <div class="container container_main">
      <div class="form">
        <div
          class="window"
          @dragenter.prevent.stop="dragging = true"
          @dragover.prevent.stop="dragging = true"
        >
          <div class="form__wrapper-img">
            <div class="form__top">
              <div class="textarea__wrapper" @paste="pasteTextarea">
              <textarea
                v-if="isNoteVisible"
                v-model="message"
                ref="messageTextarea"
                class="textarea"
                name="text"
                placeholder="Write your note here"
                autofocus
                spellcheck="false"
              />

                <textarea
                  v-else
                  v-model="currentMessage"
                  class="textarea"
                  name="text"
                  placeholder="Write your note here"
                  :disabled="!isNoteVisible"
                  :readonly="!isNoteVisible"
                  autofocus/>

                <div class="form__file-list">
                  <input style="display: none" multiple type="file" ref="files" @change="onFileChange"/>
                  <button v-for="file in files" class="form__filename">
                    <FileIcon :file="file" :hideImage="!isNoteVisible"/>
                    <span v-if="isNoteVisible" class="form__filename-title">
                      {{file.file && file.file.name}}
                    </span>
                    <span v-else class="form__filename-title">
                      {{file.file && file.file.name && new Array(file.file.name.length).fill('*').join('')}}
                    </span>
                    <span class="form__filename-delete" @click="deleteAttachment(file)">x</span>
                  </button>
                  <canvas ref="imgCanvas" style="display: none;"></canvas>
                </div>
              </div>
              <button @click="toggleNoteVisibility" class="button__open-text">
                <img src="@/assets/images/eye.svg" alt="eye">
              </button>

            </div>
          </div>
          <div class="form__bottom">
            <div class="form__bottom-left">
              <!--              <span>-->
              <button
                id="home-button-attachment"
                class="button__attachment"
                @click="onClickAddAttachment"
                @blur="hideAttachmentPopover"
                v-show="!isProfileLoading"
                :disabled="isNoteCreating"
              >
                <img src="@/assets/images/attachment.svg" alt="attachment">
              </button>
              <b-popover
                :show.sync="isShowAttachmentPopover"
                target="home-button-attachment"
                placement="topright"
                triggers=""
              >
                Max file size of attachment is 5MB
              </b-popover>
              <!--              </span>-->
              <button
                v-show="!isProfileLoading"
                @click="toggleNoteSettings"
                class="button__attachment d-none d-lg-block"
                :class="isVisibleSettings ? 'button__attachment_active' : null"
              >
                <img src="@/assets/images/settings_purple.svg" alt="settings">
              </button>

              <span v-if="hasRecordingcapability">
                <button
                  v-if="!isRecording"
                  @click="startRecord"
                  class="button__attachment d-lg-block"
                  :class="isVisibleSettings ? 'button__attachment_active' : null"
                  :disabled="isNoteCreating"
                >
                  <img width="18" src="@/assets/images/microphone-solid.svg" alt="settings">
                </button>
                <button
                  v-else
                  @click="stopRecord"
                  class="button__attachment d-lg-block"
                  :disabled="isNoteCreating"
                >
                  <img width="18" src="@/assets/images/stop-solid.svg" alt="settings">
                </button>
              </span>
              <b-spinner v-if="isProfileLoading" small variant="primary"></b-spinner>
            </div>
            <div v-if="isVisibleSettings" class="form__bottom-right form__bottom-right_desktop">
              <div
                class="ttl-selector"
                :class="{ open: isTTLSelectorOpen }"
                tabindex="0"
                @click="isTTLSelectorOpen = !isTTLSelectorOpen"
                @blur="isTTLSelectorOpen = false"
              >
                <div class="ttl-selector-current">
                  <span class="ttl-selector-icon">
                    <img class="setting-icon" src="@/assets/images/timer.svg" alt="attachment"></span>
                  <span class="ttl-selector-text">
                  {{selectedTTLObjectLabel}}
                </span>
                  <span><img src="@/assets/images/arrow.svg" alt="arrow"></span>
                </div>
                <ul class="ttl-selector__list">
                  <li
                    v-for="(ttlObject, index) in ttlList"
                    @click="onClickTTLObject(index)"
                    class="ttl-selector__list-item"
                  >
                    <span class="ttl-selector-text">{{ttlObject.label}}</span>
                  </li>
                </ul>
              </div>
              <div class="field__password">
              <span class="field__password-icon">
                <img class="setting-icon" src="@/assets/images/lock.svg" alt="lock">
              </span>
                <input
                  class="field__password__input"
                  type="text"
                  v-model="selectedPassword"
                  placeholder="Enter password"
                />
              </div>
            </div>

            <div class="form__bottom-right form__bottom-right_mobile">
              <button @click="openMobileNoteSettings" class="button__attachment button__attachment_mobile">
                <img src="@/assets/images/settings.svg" alt="settings">
              </button>
              <button @click="toggleNoteVisibility" class="button__attachment button__attachment_mobile">
                <img src="@/assets/images/eye.svg" alt="open the password">
              </button>
            </div>
          </div>

          <div v-if="noteCreatingError" class="form__bottom-error">
            {{noteCreatingError}}
          </div>

          <div
            v-if="dragging"
            class="drag-overlay"
            @drop.prevent="onDropFile"
            @dragleave="dragging=false"
          >
            Drop files here to upload
          </div>
        </div>
        <button
          @click="createNote"
          class="button__submit"
          type="submit"
          :disabled="isNoteCreating"
        >
          Create note
          <b-spinner v-if="isNoteCreating" small variant="dark"></b-spinner>
        </button>

        <div class="home__progress-wrapper" v-if="isNoteCreating">
          <b-progress :value="noteCreatingProgress" show-progress animated></b-progress>
        </div>

        <b-modal
          id="confirm-modal-filesize-restrictions"
          ref="confirmModalFileSizeRestrictions"
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          hide-header
          hide-header-close
          centered
          :visible="isVisibleFileSizeRestrictionsModal"
          body-class="note-confirm-modal__body"
          dialog-class="note-confirm-modal__dialog"
          modal-class="note-confirm-modal modal-note modal__compress"
        >

          <div
            v-if="imagesForOptimize.length && isFreeUser"
            class="note-confirm-modal__content"
          >
            <b-button class="note-confirm-modal__close" @click="closeRestrictionsModal">
              <img src="@/assets/images/cross_purple.svg" alt="close">
            </b-button>
            <img class="note-confirm-modal__icon" src="@/assets/images/compress.svg" alt="compress">
            <div class="note-confirm-modal__header">
              <h3 class="note-confirm-modal__title">Your plan allows you to upload files no more than
                {{maxFileSizeInBytesFormatted}}.</h3>
              <p class="note-confirm-modal__description">But you can compress the image by converting it to .JPEG
                format</p>
            </div>
            <div class="note-confirm-modal__actions">
              <button
                class="note-confirm-modal__button note-confirm-modal__read-button button__submit"
                @click="compressJPEGFiles"
                :disabled="isOptimizingImages"
              >
                Converting to .JPG
                <b-spinner v-if="isOptimizingImages" small variant="dark"></b-spinner>
              </button>
              <button
                class="note-confirm-modal__button note-confirm-modal__later-button"
                @click="closeRestrictionsModal"
                :disabled="isOptimizingImages"
              >
                Upload other image
              </button>
            </div>
          </div>

          <!-- Notification for >10MB files on free plan -->
          <div
            v-else-if="!imagesForOptimize.length && countOversizedFiles && isFreeUser"
            class="note-confirm-modal__content"
          >
            <b-button class="note-confirm-modal__close" @click="closeRestrictionsModal">
              <img src="@/assets/images/cross_purple.svg" alt="close">
            </b-button>
            <img class="note-confirm-modal__icon" src="@/assets/images/compress.svg" alt="compress">
            <div class="note-confirm-modal__header">
              <h3 class="note-confirm-modal__title">
                Your plan allows you to upload files no more than {{maxFileSizeInBytesFormatted}}.
              </h3>
              <p class="note-confirm-modal__description">
                Please, go to
                <router-link class="text-primary" :to="{name: 'choose-subscription'}">Subscriptions</router-link>
                or choose other files
              </p>
            </div>
            <div class="note-confirm-modal__actions">
              <button
                class="note-confirm-modal__button note-confirm-modal__read-button button__submit"
                @click="closeRestrictionsModal"
                :disabled="isOptimizingImages"
              >
                Upload another file
                <b-spinner v-if="isOptimizingImages" small variant="dark"></b-spinner>
              </button>
              <button
                class="note-confirm-modal__button note-confirm-modal__later-button"
                @click="closeRestrictionsModal"
                :disabled="isOptimizingImages"
              >
                Upload other image
              </button>
            </div>
          </div>

          <!-- Image compress suggestion for >10MB files on unlimited plan -->
          <div
            v-else-if="imagesForOptimize.length && !isFreeUser"
            class="note-confirm-modal__content"
          >
            <b-button class="note-confirm-modal__close" @click="closeRestrictionsModal">
              <img src="@/assets/images/cross_purple.svg" alt="close">
            </b-button>
            <img class="note-confirm-modal__icon" src="@/assets/images/compress.svg" alt="compress">
            <div class="note-confirm-modal__header">
              <h3 class="note-confirm-modal__title">
                Your images is weight more than {{minFilesizeToCompressForUnlimitedFormatted}}.
              </h3>
              <p class="note-confirm-modal__description">
                But you can compress the image by converting it to .JPEG format
              </p>
            </div>
            <div class="note-confirm-modal__actions">
              <button
                class="note-confirm-modal__button note-confirm-modal__read-button button__submit"
                @click="compressJPEGFiles"
                :disabled="isOptimizingImages"
              >
                Converting to .JPG
                <b-spinner v-if="isOptimizingImages" small variant="dark"></b-spinner>
              </button>
              <button
                class="note-confirm-modal__button note-confirm-modal__later-button"
                @click="addFilesWithoutCompressingForUnlimitedPlan"
                :disabled="isOptimizingImages"
              >
                Keep exist
              </button>
            </div>
          </div>

        </b-modal>

        <b-modal
          id="confirm-modal"
          ref="confirmModal"
          hide-footer
          hide-header
          centered
          :visible="isVisibleMobileSettingsModal"
          body-class="note-confirm-modal__body"
          dialog-class="note-confirm-modal__dialog"
          modal-class="note-confirm-modal modal-settings"
        >

          <div class="modal__wrapper">
            <h2 class="modal__wrapper-title">Note settings</h2>
            <b-button class="modal__close" @click="closeMobileNoteSettings">
              <img src="@/assets/images/cross.svg" alt="close">
            </b-button>
          </div>
          <div
            class="ttl-selector"
            :class="{ open: isTTLSelectorOpen }"
            tabindex="0"
            @click="isTTLSelectorOpen = !isTTLSelectorOpen"
            @blur="isTTLSelectorOpen = false"
          >
            <div class="ttl-selector-current">
              <span class="ttl-selector-icon"><img src="@/assets/images/timer-purple.svg" alt="attachment"></span>
              <span class="ttl-selector-text">
              {{selectedTTLObjectLabel}}
            </span>
              <span class="ttl-selector-img"><img src="@/assets/images/arrow_white.svg" alt="arrow"></span>
            </div>
            <ul class="ttl-selector__list">
              <li
                v-for="(ttlObject, index) in ttlList"
                @click="onClickTTLObject(index)"
                class="ttl-selector__list-item"
              >
                <span class="ttl-selector-text">{{ttlObject.label}}</span>
              </li>
            </ul>
          </div>

          <div class="field__password">
            <span class="field__password-icon"><img src="@/assets/images/lock-purple.svg" alt="lock"></span>
            <input
              class="field__password__input"
              type="text"
              v-model="selectedPassword"
              placeholder="Enter password"
            />
          </div>
        </b-modal>
      </div>

    </div>
  </div>
</template>

<script>
  import {
    createNote,
    getFileDataURL,
    getTTLList,
  } from '../../utils'
  import * as generator from 'generate-password'
  import CompiledHtml from '../partials/CompiledHtml.js'
  import Compressor from 'compressorjs';
  import * as numeral from 'numeraljs';
  import Recorder from 'recorder-js';
  import RecordRTC from 'recordrtc';
  import heic2any from "heic2any";
  import {MODULE_NAME as PROFILE} from "@/store/modules/profile";
  import FileIcon from "@/views/partials/FileIcon";


  export default {
    name: 'Home',
    components: {
      CompiledHtml,
      FileIcon,
    },
    props: {},
    data() {
      return {
        message: '',
        files: [],
        isNoteVisible: true,
        postFormData: new FormData(),
        ttlList: getTTLList(),
        selectedTTLIndex: 0,
        selectedPassword: '',
        isTTLSelectorOpen: false,
        isNoteCreating: false,
        noteCreatingProgress: 0,
        noteCreatingError: '',
        isShowAttachmentPopover: false,
        attachmentPopoverText: '',
        isVisibleSettings: false,
        isVisibleMobileSettingsModal: false,
        isVisibleFileSizeRestrictionsModal: false,
        dragging: false,
        currentMessageHtml: '',
        imagesForOptimize: [],
        countOversizedFiles: 0,
        isOptimizingImages: false,
        minFilesizeToCompressForUnlimited: 1024 * 1024 * 10,
        isRecording: false,
        recorder: null,
        recordGumStream: null,
        audioRecorderSettings: {},
      }
    },
    mounted() {
      if (this.$route.params.replyMessage) {
        this.message = this.$route.params.replyMessage
      }

      if (this.$route.params.files) {
        this.addFileAttachments(this.$route.params.files)
      }

      if (this.$route.params.dropFilesEvent) {
        this.onDropFile(this.$route.params.dropFilesEvent)
      }

      console.log('ref', this.$refs.htmlField)

      if (this.$refs.messageTextarea) {
        this.$nextTick(() => {
          this.$refs.messageTextarea.focus()

          if (typeof this.$refs.messageTextarea.selectionStart == "number") {
            this.$refs.messageTextarea.selectionStart = this.$refs.messageTextarea.selectionEnd = this.$refs.messageTextarea.value.length;
          } else if (typeof this.$refs.messageTextarea.createTextRange != "undefined") {
            this.$refs.messageTextarea.focus();
            var range = this.$refs.messageTextarea.createTextRange();
            range.collapse(false);
            range.select();
          }
        })
      }

      this.audioRecorderSettings = this.getAudioRecorderSettings()
    },
    computed: {
      selectedTTLObjectLabel() {
        const selectedTTLObject = this.ttlList[this.selectedTTLIndex]
        return selectedTTLObject ? selectedTTLObject.label : ''
      },
      selectedTTLObjectValue() {
        const selectedTTLObject = this.ttlList[this.selectedTTLIndex]
        return selectedTTLObject ? selectedTTLObject.value : ''
      },
      currentMessage() {
        return this.isNoteVisible ? this.message : new Array(this.message.length).fill('*').join('')
      },
      profileState() {
        return this.$store.state[PROFILE].profile
      },
      isProfileLoading() {
        return this.profileState.loading
      },
      subscription() {
        return this.profileState.data && this.profileState.data.subscription
      },
      isFreeUser() {
        return !this.subscription || this.subscription.isFree
      },
      isUnlimitedProfilePlan() {
        return this.subscription && this.subscription.type === 'unlimited' || false
      },
      maxFileSizeInBytes() {
        return this.getMaxFileSizeInBytes(this.profileState.data)
      },
      maxFileSizeInBytesFormatted() {
        return numeral(this.maxFileSizeInBytes).format('0b')
      },
      minFilesizeToCompressForUnlimitedFormatted() {
        return numeral(this.minFilesizeToCompressForUnlimited).format('0b')
      },
      hasRecordingcapability() {
        return navigator.mediaDevices && navigator.mediaDevices.getUserMedia || false
      }
    },
    watch: {
      selectedPassword(newVal) {
        let re = /[^A-Za-z0-9]/gi;
        this.$set(this, 'selectedPassword', newVal.replace(re, ''));
      }
    },
    methods: {
      onDragOver(e) {
        console.log('dragover')
      },
      onDropFile(e) {
        if (this.isNoteCreating) {
          this.dragging = false
          return
        }

        this.dragging = false
        let droppedFiles = e.dataTransfer.files;
        console.log(e.dataTransfer.files)

        if (!droppedFiles || !droppedFiles.length) return;
        this.addFileAttachments(e.dataTransfer.files)
      },
      onClickTTLObject(index) {
        this.selectedTTLIndex = index
      },
      submitFiles() {
        let formData = new FormData();
        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          formData.append('files[' + i + ']', file);
        }
      },
      handleFilesUpload() {
        console.log('this.$refs.files.files', this.$refs.files.files)
        this.files = this.$refs.files.files;
      },
      showAttachmentPopover(text) {
        this.attachmentPopoverText = text
        this.isShowAttachmentPopover = true
      },
      hideAttachmentPopover() {
        this.isShowAttachmentPopover = false
      },
      async onFileChange(event) {
        await this.addFileAttachments(event.target.files)
      },
      async addFileAttachments(files) {
        if (this.isNoteCreating) return
        console.log('addFileAttachments', files)

        this.imagesForOptimize = []
        this.countOversizedFiles = 0

        for (const file of files) {
          const isImageForCompress = file.type.startsWith('image/heic') || file.type.startsWith('image/jpeg')

          if (this.isFreeUser) {

            if (file.size > this.maxFileSizeInBytes) {
              this.countOversizedFiles += 1

              if (isImageForCompress) {
                file.isCanCompressed = true
                this.imagesForOptimize.push(file)
              }

              continue;
            }

          } else {

            if (file.size > this.minFilesizeToCompressForUnlimited) {
              if (isImageForCompress) {
                file.isCanCompressed = true
                this.imagesForOptimize.push(file)
                continue;
              }

            }
          }

          // if (isImageForCompress &&
          //   (file.size > this.maxFileSizeInBytes || !this.subscription.isFree && file.size > this.minFilesizeToCompressForUnlimited)
          // ) {
          //   console.log('addFileAttachments: condition', file)
          //   if (isImageForCompress) {
          //     file.isCanCompressed = true
          //     this.imagesForOptimize.push(file)
          //   }
          //
          //   if (this.subscription.isFree) {
          //     this.countOversizedFiles += 1
          //   }
          //   // this.showAttachmentPopover()
          //   continue;
          //   // return
          // }

          await this.addFile(file)
        }

        console.log('imagesForOptimize', this.imagesForOptimize)
        if (this.countOversizedFiles || this.imagesForOptimize.length) {
          this.openFilesizeRestrictionsModal()
        }

        // console.log('this.postFormData', this.postFormData)
      },
      async addFile(file, options = {}) {
        const isImage = file.type.startsWith('image/')
        const notRemoveExif = options.notRemoveExif || false
        let targetFile = file

        if (isImage && !notRemoveExif && false) {
          console.log('start remove exif data')
          const blob = await this.removeExif(file)
          console.log('end remove exif data, new blob=', blob)
          targetFile = new File([blob], file.name, {
            name: file.name,
            lastModified: file.lastModified,
            size: file.size,
            type: file.type,
          })
        }

        let imageData

        try {
          imageData = isImage ? await getFileDataURL(targetFile) : ''
        } catch (e) {
          console.error(e)
        }
        // Max filesize = 5mb
        const fileObject = {
          file: targetFile,
          isImage: isImage,
          imageData,
        }
        this.files.push(fileObject)

        // console.log('img', await this.loadImage(await getFileDataURL(file)))
        console.log('Home.addFile: files have added', fileObject)
      },
      async removeExif(file) {
        return URL && URL.createObjectURL ? this.loadImage(URL.createObjectURL(file)) : this.loadImageReader(file)
      },

      loadImageReader(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        const self = this
        return new Promise(resolve => {
          reader.onload = async function (ev) {
            const blob = await self.loadImage(ev.target.result, file.name);
            resolve(blob)
          };
        })
      },

      loadImage(file) {
        var img = new Image();
        img.src = file;
        const self = this

        console.log('loadImage', file, img)
        return new Promise(async (resolve, reject) => {
          img.onload = async function () {
            const blob = await self.imageToBlob(this, img.naturalWidth, img.naturalHeight);
            resolve(blob)
          };
          img.onerror = function (e) {
            console.log('error loadImage', e)
            reject()
          }
        })
      },
      imageToBlob(img, w, h) {
        var c = this.$refs.imgCanvas
        var cx = c.getContext('2d');

        c.width = w;
        c.height = h;
        cx.drawImage(img, 0, 0, w, h);
        // const imgData = c.toDataURL('image/jpeg')
        // console.log('img', imgData)

        return new Promise(resolve => {
          c.toBlob(function (blob) {
            // var newImg = document.createElement('img'),
            // const bloburl = URL.createObjectURL(blob);
            // console.log('blob', blob)
            resolve(blob)
          })
        })
      },
      onClickAddAttachment() {
        this.$refs.files.value = ''
        this.$refs.files.click()
      },
      deleteAttachment(targetFile) {
        this.files = this.files.filter(file => file !== targetFile)
      },
      async createNote() {
        this.noteCreatingError = ''

        if (this.isRecording) {
          await this.stopRecord()
        }

        if (!this.files.length && !this.message) {
          this.noteCreatingError = 'Please, attach a file or create a message'
          return
        }

        this.isNoteCreating = true

        const hasSpecifiedPassword = !!this.selectedPassword
        const password = this.selectedPassword || generator.generate({
          length: 10,
          numbers: true
        });

        try {

          const {noteId, encodedKey} = await createNote({
            message: this.message,
            files: this.files.map(file => file.file),
            burnDate: this.selectedTTLObjectValue,
            password,
            onProgress: (percent) => this.updateProgress(percent)
          })
          this.$router.push({
            name: 'note-created',
            params: {noteId: noteId, notePwd: password, hasSpecifiedPassword},
          })
        } catch (e) {
          console.error(e)
          this.noteCreatingError = e.response && e.response.data && e.response.data.message || e.message
        }
        this.isNoteCreating = false
      },
      onClickSelectedTTL() {
        this.$refs.selectTTL.click()
      },
      toggleNoteVisibility() {
        this.isNoteVisible = !this.isNoteVisible
      },
      openMobileNoteSettings() {
        this.isVisibleMobileSettingsModal = true
      },
      closeMobileNoteSettings() {
        this.isVisibleMobileSettingsModal = false
      },
      toggleNoteSettings() {
        this.isVisibleSettings = !this.isVisibleSettings
      },
      async pasteTextarea(pasteEvent, callback) {
        // pasteEvent.preventDefault()
        if (pasteEvent.clipboardData === false) {
          if (typeof (callback) == "function") {
            callback(undefined);
          }
        }

        var items = pasteEvent.clipboardData.items;

        if (items === undefined) {
          if (typeof (callback) == "function") {
            callback(undefined);
          }
        }

        const attachments = []

        for (var i = 0; i < items.length; i++) {
          var blob = items[i].getAsFile();

          if (blob) {
            attachments.push(blob)
          }
        }

        this.addFileAttachments(attachments)
      },
      closeRestrictionsModal() {
        this.isVisibleFileSizeRestrictionsModal = false
        this.imagesForOptimize = []
      },
      async openFilesizeRestrictionsModal() {
        this.isVisibleFileSizeRestrictionsModal = true
      },
      async compressJPEGFiles() {
        this.isOptimizingImages = true
        try {
          const newFiles = []

          console.log('compressJPEGFiles: start')
          for (let file of this.imagesForOptimize) {
            const quality = this.maxFileSizeInBytes !== Infinity ? this.maxFileSizeInBytes / 2 / file.size : 8
            console.log('old size', file.size / 1024)

            let fileExt = 'jpg'
            let newFile

            switch (file.type) {
              case 'image/heic':
                newFile = await heic2any({
                  blob: file,
                  toType: 'image/jpeg',
                  quality: quality, // cuts the quality and size by half
                })
                break
              case 'image/jpeg':
                newFile = await new Promise(async (resolve, reject) => {
                  new Compressor(file, {
                    quality,
                    success(result) {
                      console.log('compressed', result)
                      resolve(result)
                    },
                    error(err) {
                      console.log(err.message);
                      reject(err)
                    },
                  });
                })

            }

            const newFileNameArray = file.name.split('.').slice(0, -1)
            newFileNameArray.push(fileExt)
            const newFileName = newFileNameArray.join('.')

            const blobFile = new File([newFile], newFileName, {
              type: 'image/jpg'
            });
            console.log('newformat', newFile.size / 1024, 'quality', quality, blobFile)
            newFiles.push(blobFile)
            await this.addFile(blobFile, {notRemoveExif: true})
          }

          console.log('compressJPEGFiles: end')
          // await this.addFileAttachments(newFiles)
        } catch (e) {
          console.error(e)
        }
        this.isOptimizingImages = false
        this.closeRestrictionsModal()

      },
      async addFilesWithoutCompressingForUnlimitedPlan() {
        for (let file of this.imagesForOptimize) {
          await this.addFile(file)
        }
        this.closeRestrictionsModal()
      },
      getMaxFileSizeInBytes(profile) {
        if (!profile) {
          // 5MB
          return 1024 * 1024 * 5
        }

        if (profile && profile.subscription.isFree) {
          // 10MB
          return 1024 * 1024 * 10
        }

        if (profile && !profile.subscription.isFree) {
          return Infinity
        }

        return 1024 * 1024 * 5
      },
      updateProgress(percent) {
        this.noteCreatingProgress = percent
      },
      isMimeTypeSupported(mimeType) {
        if (typeof MediaRecorder === 'undefined') {
          return false;
        }

        if (typeof MediaRecorder.isTypeSupported !== 'function') {
          return true;
        }

        return MediaRecorder.isTypeSupported(mimeType);
      },
      getAudioRecorderSettings() {
        if (this.isMimeTypeSupported('audio/webm')) {
          return {
            mimeType: 'audio/webm',
            fileExtension: 'webm',
            recorderType: null,
          }
        }

        if(this.isMimeTypeSupported('audio/ogg')) {
          return {
            mimeType: 'audio/ogg',
            fileExtension: 'ogg',
            recorderType: null,
          }
        }

        if (this.isMimeTypeSupported('audio/wav')) {
          return {
            mimeType: 'audio/wav',
            fileExtension: 'wav',
            recorderType: RecordRTC.StereoAudioRecorder,
          }
        }

        return {
          mimeType: 'audio/wav',
          fileExtension: 'wav',
          recorderType: null,
        }
      },
      async startRecord() {
        this.isRecording = true

        try {
          if (navigator && navigator.permissions && navigator.permissions.query) {
            try {
              const permissions = await navigator.permissions.query({name: 'microphone'})
              console.log('perm', permissions)
              if (permissions.state === 'denied') {
                this.$bvToast.toast('Please, grant access to microphone', {
                  variant: 'danger',
                  solid: true,
                  autoHideDelay: 3000,
                })
              }
            } catch (e) {
              console.error('permissions error', e)
            }
          }

          const isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          const options = {
            type: 'audio',
            numberOfAudioChannels: isEdge ? 1 : 2,
            checkForInactiveTracks: true,
            bufferSize: 16384,
            recorderType: this.audioRecorderSettings.recorderType
          };


          if (isSafari || isEdge) {
            options.recorderType = RecordRTC.StereoAudioRecorder;
          }

          if (navigator.platform && navigator.platform.toString().toLowerCase().indexOf('win') === -1) {
            options.sampleRate = 48000; // or 44100 or remove this line for default
          }

          if (isSafari) {
            options.sampleRate = 44100;
            options.bufferSize = 4096;
            options.numberOfAudioChannels = 2;
          }

          if (this.recorder) {
            this.recorder.destroy();
            this.recorder = null;
          }

          const mic = await navigator.mediaDevices.getUserMedia({
            audio: isEdge ? true : {
              echoCancellation: false
            }
          })
          try {
            this.recorder = RecordRTC(mic, options);

            this.recorder.startRecording();

          } catch (e) {
            console.error('RecordRTC', e)
          }


        } catch (e) {
          this.isRecording = false
          console.error('recording error', e)
          this.$bvToast.toast('Audio recording error', {
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000,
          })
        }
      },
      async stopRecord() {
        this.isRecording = false

        try {
          if (this.recorder) {

            await new Promise(async (resolve, reject) => {
              this.recorder.stopRecording(() => {
                resolve()
              });
            })

            console.log('await stop inner')

            let blob = this.recorder.getBlob();

            await this.addFileAttachments([
              new File([blob], `audio-record.${this.audioRecorderSettings.fileExtension}`, {
                name: `audio-record.${this.audioRecorderSettings.fileExtension}`,
                lastModified: Date.now(),
                size: blob.size,
                type: this.audioRecorderSettings.mimeType,
              })
            ])

          }

        } catch (e) {
          console.error(e)
        }

      },

    }
  }
</script>
